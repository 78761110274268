<template>
  <div>
    <button class="ui button" type="button" @click="refreshGraph">
      倍音列の更新
    </button>
    <table class="ui unstackable blue table" style="max-width: 600px">
      <thead>
        <tr>
          <th></th>
          <th>基音からの周波数比(1~)</th>
          <th>音量(0 ~ 1.0)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(v, index) in harmonicsFreqs">
          <td>{{ index }}</td>
          <td>
            <div class="ui input">
              <input v-model="v.freq">
            </div>
          </td>
          <td>
            <div class="ui input">
              <input v-model="v.amp">
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
const paramA = 0.70
const paramB = 1.4
const paramC = 4.0
const paramD = 1.25
const log12to10 = 12 / Math.log10(2)
const calculatePoints = 1000

export default {
  props: {
    bindto: String
  },
  data() {
    return {
      harmonicsFreqs: [
        {freq: 1.0, amp: 1.0},
        {freq: 2.0, amp: 0.8},
        {freq: 3.0, amp: 0.6},
        {freq: 4.0, amp: 0.4},
        {freq: 5.0, amp: 0.2},
        {freq: 6.0, amp: 0.1},
      ]
    }
  },
  created() {
    this.refreshGraph()
  },
  computed: {
    harmonicsCount() {
      return this.harmonicsFreqs.length
    },
    harmonicsPitches() {
      return this.harmonicsFreqs.map((f) => this.hz2pitch(f.freq))
    }
  },
  methods: {
    hz2pitch(hz) {
      return log12to10 * Math.log10(hz)
    },
    calculateDissonance() {
      var results = []
      for (var x=0; x<=calculatePoints; x+=1) {
        const diffPitch = (x/calculatePoints) * 12
        var totalDissonance = 0.0
        for (var i=0; i<this.harmonicsCount; ++i) {
          for (var j=0; j<this.harmonicsCount; ++j) {
            const x12 = Math.abs((this.harmonicsPitches[j] + diffPitch) - this.harmonicsPitches[i])
            const v12 = this.harmonicsFreqs[i].amp * this.harmonicsFreqs[j].amp
            totalDissonance += v12 * paramC * (Math.exp(-paramA * Math.pow(x12, paramD)) - Math.exp(-paramB * Math.pow(x12, paramD)))
          }
        }
        results.push([diffPitch, totalDissonance/2])
      }
      return results
    },
    plotGraph(newValues) {
      Plotly.newPlot($(this.bindto)[0],
        [{x: newValues.map((v) => v[0]), y: newValues.map((v) => v[1])}],
        {margin: {t: 0}, xaxis: {title: '半音(semitones)'}, yaxis: {title: '不協和度(dissonance)'}}
      )
    },
    refreshGraph() {
      this.plotGraph(this.calculateDissonance())
    }
  }
}
</script>
