<template>
  <div>
    <button class="ui button" type="button" @click="refreshGraph">
      グラフ更新
    </button>
    <hr/>
    <div class="ui form" style="max-width: 600px">
      <div class="field">
        <label>データ(改行区切り)</label>
        <textarea v-model="dataText"></textarea>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    bindto: String
  },
  data() {
    return {
      dataText: ''
    }
  },
  created() {
    this.refreshGraph()
  },
  computed: {
    dataItems() {
      return this.dataText.split(/\r\n|\n/).filter((v) => v.length > 0).map((v) => parseFloat(v))
    },
  },
  methods: {
    calculateData() {
      let results = []
      const items = this.dataItems
      for (let x = 0; x < items.length; ++x) {
        results.push([x, items[x]])
      }
      return results
    },
    plotGraph(newValues) {
      Plotly.newPlot($(this.bindto)[0],
        [{x: newValues.map((v) => v[0]), y: newValues.map((v) => v[1])}],
        {margin: {t: 0}, xaxis: {title: 'index'}, yaxis: {title: 'value'}}
      )
    },
    refreshGraph() {
      this.plotGraph(this.calculateData())
    }
  }
}
</script>
