/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import Vue from 'vue'

import DissonanceCurve from './dissonance_curve.vue'
import Graphtool from './graphtool.vue'

window.vbus = new Vue()
window.$vue_data = {}
window.Vue = Vue

Vue.component("dissonance-curve", DissonanceCurve)
Vue.component("graphtool", Graphtool)

//document.addEventListener('DOMContentLoaded', () => {
//  new Vue({ el: '#vue_root', data: window.$vue_data })
//})
